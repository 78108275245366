/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:44:55
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:28:46
 */
import Age from './age.vue'
export default Age
